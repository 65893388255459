/* Main page */

body { 
    background-color: wheat;
    height: 400px;
    width: 100%;
    margin: 20;
    padding-top: 5rem;
    padding-bottom: 0;
}




.dubbe {
    height: 800px;
    list-style-type: none;
    display: flex;
    justify-content: center; 
    padding: 1rem;
    align-items: top;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 0;
}

.dubbe a { 
    text-decoration: none;
    color: black;
    padding: 0.5rem;
    
}

.right-box, .left-box {
    width: 300px;
    height: 200px;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center; 
    font-size: 2rem;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.right-box:hover {
  background-color: rgba(0, 229, 255, 0.8);
  cursor: pointer;
  transform: scale(1.05);
}

.left-box:hover {
    background-color: rgba(255, 170, 0, 0.8);
    cursor: pointer;
    transform: scale(1.05);
  }

.right-box {
    background-color: rgb(33, 147, 155);
}

.left-box {
    background-color: rgb(187, 132, 14);
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .right-box:hover,
  .left-box:hover {
    animation: pulse 2.5s ease-in-out infinite alternate;
  }
  
  /* Styling the anchor tag */
  .dubbe a {
    text-decoration: none;
    color: black;
    padding: 0.5rem;
  }

.welcome {
    text-align: center;
    box-sizing: border-box;
    display: flex;
    height: 800px;
    font-size: 5rem;
    padding-top: 5rem;
    padding-bottom: 0rem;
    background-color: wheat;
    color: rgb(255, 255, 255);
    background-size: 100% auto;
    align-items: center;

    
}

.start {
    background-color: white;
    color: rgb(153, 102, 51); 
    border: 2px solid rgb(153, 102, 51); 
    border-radius: 20px; 
    padding: 10px 20px; 
    font-size: 1.5rem; 
    cursor: pointer; 
    transition: background-color 0.3s, color 0.3s; 
    width: max-content;
}

.start:hover {
    background-color: rgb(153, 102, 51); 
    border: 2px solid rgb(153, 102, 51); 
    color: white; 
}

.welc-cc {
    box-sizing: border-box;
    display: flex;
    text-align: right;
    flex-direction: column;
    padding: 5rem;
    margin-left: auto;

}

.welc-cc-a{
    list-style: none;
    text-decoration: none;
}


/* CSS For the about page */

.about {
    text-align: center;
    background-color: aliceblue;
    padding: 2rem;
}

.aboutt {
    text-align: center;
    background-color: rgb(255, 196, 124);
    padding: 2rem;
    font-size: 4rem;
    text-align: center;
}

.questt {
    font-size: 7rem;
    padding: 5rem;
}


.whatrwe {
    background-color: burlywood;
    box-sizing: border-box;
    padding: 10rem;
    height: 500px;
    justify-content: space-between;
    font-size: 1.5rem;
    text-align: center;
    gap: 1rem;
    margin-bottom: 0;
    

}

.whyus {
    color: rgb(255, 255, 255);
    text-align: left;
    box-sizing: border-box;
    display: flex;
    height: 800px;
    padding-top: 5rem;
    padding-bottom: 0; 
    background-color: wheat;
    background-size: 100% auto;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}

.whyus-left {
    padding-right: 2rem; /* Adjust as needed */
    font-size: 5rem;
}

.whyus-right {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    padding-left: 4rem;
}

.whyus-right ul {
    list-style-type: none;
    padding: 0;
}

.whyus-right ul li {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    animation: pulse 0.5s ease-in-out infinite alternate;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        background-color: rgba(205, 133, 63, 0.5); /* Darker wheat color with 50% opacity */
    }
    50% {
        transform: scale(1.05);
        background-color: rgba(205, 133, 63, 0.8); /* Darker wheat color with 80% opacity */
    }
    100% {
        transform: scale(1);
        background-color: rgba(205, 133, 63, 0.5); /* Darker wheat color with 50% opacity */
    }
}

.right-box:hover,
.left-box:hover {
    animation: pulse 1s ease-in-out infinite alternate; /* Slower pulse animation */
}


