.leftbox {
    width: 650px;
    height: 650px;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center; 
    font-size: 5rem;
    background-color: rgb(0, 0, 0);
    color: wheat;
}