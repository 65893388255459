.signup-cont {
    height: 100vh; /* Set height to 100% of viewport height */
    background-color: wheat;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.signup-form {
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a small shadow */
    background-color: wheat;
    padding: 2rem; /* Add padding for spacing */
    max-width: 700px; /* Set maximum width */
    width: 90%; /* Set width to 90% of the viewport */
    text-align: center;
}

.signup-form h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.signup-form h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"] {
    padding: 10px;
    margin: 10px;
    border: 1px solid rgb(153, 102, 51); /* Brown border */
    border-radius: 5px;
    width: 80%;
    max-width: 400px;
    font-size: 1.2rem;
}

.signup-submit {
    background-color: rgb(153, 102, 51); /* Brown color */
    color: white; /* White text color */
    padding: 10px 20px;
    margin-top: 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.3rem;
    transition: background-color 0.3s, color 0.3s;
}

.signup-submit:hover {
    background-color: rgb(209, 145, 49); /* Darker brown color on hover */
}