* {
    box-sizing: border-box;
}

.footer {
    background-color: rgb(218, 170, 108);
    height: 10rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 0;
    padding-top: 0;


}

.content {
    list-style-type: none;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin-top: 0;

}

.footer ul{
    list-style: none;
    padding-left: 3rem; 

}

