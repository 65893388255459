* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.headhead {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.headline {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 1rem;
    font-size: 3rem;
}

.navbody {
    background-color: rgb(153, 102, 51); 
    color: white;
    display: flex;
    justify-content: space-between;
    text-align: center;
    text-decoration: none;
    position: fixed; 
    top: 0; 
    width: 100%; 
    z-index: 1000; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s; 
}

.nav {
    justify-content: space-between;
    display: flex;
    gap: 1.5rem;
    text-decoration: none;
    list-style-type: none;
    align-items: center;
    padding-right: 1rem;
}


.nav a{
    text-decoration: none;
    font-size: 1.3rem;
    color: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;

}

.nav :active {
    color: rgb(153, 102, 51); 
    background-color: rgb(209, 105, 0);
}

.nav a:hover {
    color: black;
}

.reglog {
    color: black;
    background-color: wheat;
    padding: 10px 20px;
    gap: 1rem;
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 2px solid rgb(153, 102, 51); 
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.reglog:hover {
    background-color: rgb(153, 102, 51); 
    color: white;
    border-color: rgb(153, 102, 51); 
}

